import React, {Component} from "react";
import BannderHead from "../../components/layout/BannderHead";
import axios from "axios";
import Pagination from "../../components/Pagination";


export default class AdminPushListPage extends Component {
    state = {
        pushs:[],
        pagination:{},
        search:'',
    }
    async componentDidMount() {
      await this.getPushs();
    }
    getPushs = async () => {
        let URL = window.admin_base_URL + '/push/list'
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page');
        if (page) URL = window.admin_base_URL + '/push/list?page=' + page
        let pagination = {};
        let data = [];
        await axios.get(URL)
            .then(res => {
                data = res.data.result
                pagination = res.data.pagination

            }).catch(err=>{
                console.error(err)
            })
        this.setState(() => {
            return {pushs: data ,pagination: pagination, currentPage: page};
        });
    }
    handleChange = event => {
        let search = event.target.value
        this.setState({search});
    }
    searchOnTitle = async  () =>{
        const URL = window.admin_base_URL +  "/push/search?fields=title&q=" + this.state.search
        let data = [];
        await axios.get(URL).then(res=>{
            data = res.data.result
        }).catch(err=>{
            console.error(err)
        })
        this.setState(() => {
            return {pushs: data};
        });
    }

    render() {
        let {pushs ,pagination,search}  = this.state;
        return (
            <div className="ps-page pb-75">
                <BannderHead title={'لیست اطلاعیه‌ها'}/>
                <div className='ml-4 mb-4 d-flex'>
                    <div className="w-50 text-right">
                        <div className='mb-3'>
                            <span>تعداد کل: {pagination.count}</span>&nbsp;;&nbsp;
                            <span>تعداد نمایش در صفحه : {pagination.limit}</span>&nbsp;;&nbsp;
                            <span>شماره صفحه : {pagination.page}</span>
                        </div>

                        <div className='input-group w-auto rounded-pill no-print'>
                            <label htmlFor="serach" className='searchInputButton'>
                                <input type="text" className="form-control rounded-pill h-auto"
                                       placeholder="جستوجو روی عنوان "
                                       onChange={this.handleChange}
                                       name='search'
                                       value={search}
                                       id='search'/>
                                <button className=" rounded-circle " type="button"
                                        onClick={this.searchOnTitle}
                                        id="button-addon1" style={{zIndex: 1}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path
                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                    </svg>
                                </button>
                            </label>


                        </div>
                    </div>

                    <div className="w-50">
                        <Pagination pagination={pagination} />
                        <a href='push/form' className="ps-btn ps-btn--sm"><i className="fa fa-plus"></i>&nbsp;ارسال دستی
                        </a>
                    </div>
                </div>
                <table className="table table-hover table-striped  text-right mb-4">
                    <thead>
                    <tr>
                        <th scope="col">عنوان</th>
                        <th scope="col">تاریخ و زمان</th>
                        <th scope="col">نوع</th>
                        <th scope="col">متن اطلاعیه</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pushs.map((item, index) => {
                        return <tr key={index}>
                            <td>{item.title}</td>
                            <td>{item.created}</td>
                            <td>{ item.type}</td>
                            <td>{item.message}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
                <Pagination pagination={pagination} />
            </div>
        );
    }

}
