import React, { Component } from "react";
import axios from "axios";
import BannderHead from "../../components/layout/BannderHead";
import Pagination from "../../components/Pagination";


export default class AdminClientListPage extends Component {
    state = {
        clients: [],
        pagination: {},
        search: '',
        client: {}
    }
    async componentDidMount() {
        await this.getClient();
    }
    getClient = async () => {
        let URL = window.admin_base_URL + '/client/list'
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page');
        if (page) URL = window.admin_base_URL + '/client/list?page=' + page
        let pagination = {};
        let data = [];
        await axios.get(URL).then(res => {
            data = res.data.result
            pagination = res.data.pagination

        }).catch(err => {
            console.error(err)
        })
        this.setState(() => {
            return { clients: data, pagination: pagination, currentPage: page };
        });
    };
    GoToEditForm = async event => {
        window.location.href = "clients/form?id=" + event.target.id
    }
    goToFixOrder = async event => {
        window.location.href = "clients/" + event.target.id + "/fixOrder"
    }
    orderHistory = async event => {
        window.location.href = "clients/" + event.target.id + "/orderHistory"
    }
    handleChange = event => {
        let search = event.target.value
        this.setState({ search });
    }
    searchOnTitle = async () => {
        const URL = window.admin_base_URL + "/client/search?fields=name&q=" + this.state.search
        let data = [];
        await axios.get(URL).then(res => {
            data = res.data.result
        }).catch(err => {
            console.error(err)
        })
        this.setState(() => {
            return { clients: data };
        });
    }
    approveClient = async event => {
        if (window.confirm("تغییر وضعیت مشتری به تایید شده ؟‌")){
            const URL = window.admin_base_URL + "/client/approve/" + event.target.id
            await axios.put(URL, {}).then(async () => {
                 await this.getClient();
            }).catch(err => {
                console.error(err)
            })
        }
    }
    createOrder = async event => {
        let client = this.state.clients.filter(item=> item._id===event.target.id)[0];
        sessionStorage.setItem('client',JSON.stringify(client));
        window.location.href = "/cart"
    }
    render() {
        let { clients, pagination, search } = this.state;
        return (
            <div className="ps-page pb-75">
                <BannderHead title={"لیست مشتری‌ها"} />
                <div className='ml-4 mb-4 d-flex'>
                    <div className="w-50 text-right">
                        <div className='mb-3'>
                            <span>تعداد کل: {pagination.count}</span>&nbsp;;&nbsp;
                            <span>تعداد نمایش در صفحه : {pagination.limit}</span>&nbsp;;&nbsp;
                            <span>شماره صفحه : {pagination.page}</span>
                        </div>

                        <div className='input-group w-auto rounded-pill no-print'>
                            <label htmlFor="serach" className='searchInputButton'>
                                <input type="text" className="form-control rounded-pill h-auto"
                                    placeholder="جستجو روی نام/نام خانوادگی "
                                    onChange={this.handleChange}
                                    name='search'
                                    value={search}
                                    id='search' />
                                <button className=" rounded-circle " type="button"
                                    onClick={this.searchOnTitle}
                                    id="button-addon1" style={{ zIndex: 1 }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path
                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                    </svg>
                                </button>
                            </label>


                        </div>
                    </div>
                    <div className="w-50">
                        <Pagination pagination={pagination} />
                        <a href='clients/form' className="ps-btn  ps-btn--sm">ایجاد مشتری</a>
                    </div>
                </div>
                <table className="table table-hover table-striped  text-right mb-4">
                    <thead>
                        <tr>
                            <th scope="col">نام / نام خانوادگی</th>
                            <th scope="col">آدرس</th>
                            <th scope="col">شماره تلفن همراه</th>
                            <th scope="col">شماره تلفن ثابت</th>
                            <th scope="col">کد پستی</th>
                            <th scope="col">نام فروشگاه</th>
                            <th scope="col">وضعیت</th>
                            <th scope="col" className="no-print">عملیات</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clients.map((item, index) => {
                            return <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.address}</td>
                                <td>{item.phone}</td>
                                <td>{item.tel}</td>
                                <td>{item.postCode}</td>
                                <td>{item.shopTitle}</td>
                                <td>{item.enabled ? "تایید شده" : 
                                <div>
                                    <span className="mb-10">تایید نشده</span>
                                    <button className='no-print ps-btn--outline ps-btn--sm' onClick={this.approveClient} id={item._id}>تایید مشتری</button>
                                    </div>}</td>
                                <td className="no-print">
                                    <button type="button" className="ps-btn ps-btn--outline ps-btn--sm mx-1 mb-10"
                                        onClick={this.GoToEditForm} id={item._id}>ویرایش
                                    </button>
                                    <button type="button" className="ps-btn ps-btn--outline ps-btn--sm mx-1"
                                            onClick={this.orderHistory} id={item._id}>سوابق سفارش
                                    </button>
                                    <button type="button" className="ps-btn ps-btn--outline ps-btn--sm mx-1"
                                            onClick={this.createOrder} id={item._id}>ثبت سفارش
                                    </button>
                                    <button type="button" className="ps-btn ps-btn--outline ps-btn--sm mx-1"
                                            onClick={this.goToFixOrder} id={item._id}>مشاهده روتین
                                    </button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <Pagination pagination={pagination} />

            </div>
        );
    }
}
