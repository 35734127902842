import React, { Component } from "react";
import BannderHead from "../../components/layout/BannderHead";
import axios from "axios";
import { groupBy } from "lodash";



export default class AdminClientFixOrderListPage extends Component {
    state = {
        orders: [],
        translate: {
            fa: {
                fast: "ارسال فوری",
                inPerson: "تحویل حضوری",
                normal: "ارسال عادی",
                Saturday: "شنبه",
                Sunday: "یکشنبه",
                Monday: "دوشنبه",
                Tuesday: "سه شنبه",
                Wednesday: "چهارشنبه",
                Thursday: "پنجشنبه",
                Friday: "جمعه",
            }

        }
    }

    constructor() {
        super();
        this.state.isShow = 'daily';
    }

    async componentDidMount() {

        await this.getFixOrders();

    }

    getFixOrders = async () => {
        const { id } = this.props.match.params;
        let URL = window.admin_base_URL + '/client/' + id + '/fix-orders'
        let data = [];
        await axios.get(URL)
            .then(res => {
                data = res.data.orders

                data.forEach(order => {
                    let total = 0;
                    order.products.forEach(item => {
                        total += parseInt(item.price)
                    })
                    order.totalPrice = total
                    order.products = order.products ? groupBy(order.products, "_id") : []
                })
            }).catch(err => {
                console.error(err)
            })
        this.setState(() => {
            return { orders: data };
        });
    }
    handleTarget = async event => {
        let target = event.target;
        this.setState(() => {
            return { isShow: target.value };
        });
    }

    render() {


        let { orders, translate } = this.state;

        return (
            <div className="ps-page pb-75">
                <BannderHead title={'سفارشات روتین مشتری'} />
                <table className="table table-hover table-striped  text-right mb-4">
                    <thead>
                        <tr>
                            <th scope="col">روز</th>
                            <th scope="col">جزئیات سفارش</th>
                            <th scope="col">مبلغ سفارش</th>
                            <th scope="col">نوع ارسال</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((item, index) => {
                            return <tr key={index}>
                                <td>{translate.fa[item.dayName]}</td>
                                <td>
                                    {Object.entries(item.products)
                                        .sort((a, b) => a[0] > b[0] ? 1 : -1)
                                        .map((item, index) => {
                                            return <div key={index}> {item[1].length} {item[1][0].title}{item[1][0].weight ? <span> .... {item[1][0].weight}&nbsp;گرم</span> : ''}</div>
                                        })}
                                </td>
                                <td>{item.totalPrice.toLocaleString()}</td>
                                <td>{this.state.translate.fa[item.howToSend]}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}
