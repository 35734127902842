import React, {Component} from "react";
import BannderHead from "../../components/layout/BannderHead";
import axios from "axios";
import { groupBy } from "lodash";

var moment = require('moment-jalaali')

function productMiniCart(product, count, index) {
    return <div className="col-12 col-md-4 product-mini-orders" key={index}>
        <div className="ps-product--horizontal ltr">
            <div className="ps-product__thumbnail no-print">
                <img src={window.file_URL + product.img} alt=""/>
            </div>
            <div className="ps-product__container align-items-center">
                <div className="ps-product__content">
                    <label className="ps-product__title">{product.title}{product.weight ? <sub> ... {product.weight} گرم</sub> : ''}</label>
                </div>
                <div className="ps-product__price" dir="rtl"><span>{count} عدد </span></div>
            </div>
        </div>
    </div>
}

export default class AdminDashboardPage extends Component {
    state = {
        products: [],
        categories: [],
        pagination: {},
        search: '',
    }

    async componentDidMount() {
        await this.getCategories();
        await this.getOrders();

    }

    getOrders = async () => {
        let data = [];
        let _products = []
        let products = []
        await axios.get(window.admin_base_URL + '/orders/daily')
            .then(async (res) => {
                data = res.data.orders
                await data.forEach((order) => {
                    products = [...products, ...order.products]
                    _products = products
                })
                products = groupBy(products, ({_id}) => _id);
            }).catch(err => {
                console.error(err)
            })
        this.setState(() => {
            return {orders: data, products, _products};
        });
    }
    getCategories = async () => {
        let data = [];
        await axios.get(window.admin_base_URL + '/category/list').then(res => {
            data = res.data.result.reverse();
        }).catch(err => {
            console.error(err)
        })
        this.setState(() => {
            return {categories: data};
        });
    };
    ChangeCategory = async (event) => {
        event.preventDefault();
        let cat_id = event.target.id
        let _products = null
        console.log(this.state._products);
        const filteredArray = this.state._products.filter(item => item.category === cat_id);
        _products = groupBy(filteredArray, ({_id}) => _id);
        this.setState(() => {
            return {products: _products};
        });
    }

    showAll = async (event) => {
        event.preventDefault();
        let _products = null
        _products = groupBy(this.state._products, ({_id}) => _id);
        this.setState(() => {
            return {products: _products};
        });
    }

    render() {
        let {categories, products} = this.state;
        return (
            <div className="ps-page pb-75">
                <BannderHead className="no-print" title={'سفارشات تولید امروز'}/>
                <BannderHead className="just-print" title={'سفارشات تولید '+moment(new Date()).format('jYYYY/jMM/jDD')}/>
                <div className="ps-section " dir='ltr'>

                    <div className="ps-section__left">
                        <div className="ps-section__content">
                            <div className="ps-block--product-menu ps-tab-root">
                                <div className="ps-block__header no-print">
                                    <ul className="ps-tab-list" dir="rtl">
                                        <li>
                                            <a href="/" onClick={this.showAll} style={{'cursor': 'pointer'}}>
                                                همه
                                            </a>
                                        </li>
                                        {categories.map((category, index) => {
                                            return <li key={index}><a href="/" onClick={this.ChangeCategory}
                                                                      style={{'cursor': 'pointer'}}
                                                                      id={category._id}>{category.title}</a></li>
                                        })}
                                    </ul>
                                </div>
                                <div className="ps-tabs">

                                    <div className="ps-tab active rtl" id="Bread">
                                        <div className="row">
                                            {Object.entries(products)
                                                .sort((a, b) => a[0] > b[0] ? 1 : -1)
                                                .map((item, index) => {
                                                    return productMiniCart(item[1][0], item[1].length, index)
                                                })}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
