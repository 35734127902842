import React, { Component, Fragment } from "react";
import 'owl.carousel/dist/assets/owl.carousel.css';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Default from "./components/Default";
import ProductListPage from "./Pages/Products/ProductListPage";
import ProductDetails from "./Pages/Products/ProductDetails";
import CartPage from "./Pages/CartPage";
import { Switch, Route} from "react-router-dom";
import HomePage from "./Pages/HomePage";
import ProfilePage from "./Pages/ProfilePage";
import BlogsPage from "./Pages/Blogs/BlogsPage";
import BranchesPage from "./Pages/BranchesPage";
import BranchDetailsPage from "./Pages/BranchDetailsPage";
import BlogDetailsPage from "./Pages/Blogs/BlogDetailsPage";
import LoginPage from "./Pages/LoginPage";
import AdminLoginPage from "./Pages/AdminPanels/AdminLoginPage";
import OpenJobsPage from "./Pages/OpenJobsPage";
import ContactUsPage from "./Pages/ContactUsPage";
import JoinUsPage from "./Pages/JoinUsPage";
import AdminPanelPage from "./Pages/AdminPanels/AdminPanelPage";
import { send } from './utils/Push'
import axios from "axios";
axios.defaults.headers.common['x-auth-token'] = document.cookie.split("=")[1];


class App extends Component {
    state = {
        title: '',
        instagram: '',
        address: '',
        phone: '',
    }
    async componentDidMount() {
        await this.getConfigs();
        await send()
    }

    getConfigs = async () => {
        let data = null;
        await axios.get(window.base_URL + '/config/list').then(async res => {
            data = res.data.result[0];
            sessionStorage.setItem('configs', JSON.stringify(data));
            this.setState(data);
        }).catch(err => {
            console.error(err)
        })
    };
    render() {
        let checkAdminPanel = window.location.pathname.includes("adminPanel") || window.location.pathname.includes("adminpanel")
        let { title,
            instagram,
        
            address,
            phone } = this.state
        return (

            <Fragment>
                {checkAdminPanel ? "" : <Navbar logo="logo.png" instagram={instagram} title={title} />}

                <Switch>
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/adminlogin" component={AdminLoginPage} />
                    <Route path="/adminPanel" component={AdminPanelPage} />
                    <Route path="/products/:id" component={ProductDetails} />
                    <Route path="/products" component={ProductListPage} />
                    <Route path="/profile" component={ProfilePage} />
                    <Route path="/openJobs" component={OpenJobsPage} />
                    <Route path="/contactUs" component={ContactUsPage} />
                    <Route path="/cart" component={CartPage} />
                    <Route path="/blogs/:id" component={BlogDetailsPage} />
                    <Route path="/blogs" component={BlogsPage} />
                    <Route path="/branches/:id" component={BranchDetailsPage} />
                    <Route path="/branches" component={BranchesPage} />
                    <Route path="/joinUs" component={JoinUsPage} />
                    <Route path="/" component={() => <HomePage instagram={instagram} />} />
                    <Route component={Default} />
                </Switch>
                <Footer logo="logo.png" instagram={instagram} address={address} phone={phone} title={title} />
            </Fragment>

        );
    }
}

export default App;
