import axios from "axios";


const publicVapidKey =
    "BHETT2lIpZfAIqoCSA4zpVMxuuaLuxmg_Um2tZMM0rCCdJKNp3ZKb-C8D1LMRGRdPyktw2NcK7R_iZnc4XUh20A";


function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export async function send() {
    await navigator.serviceWorker.register("/sw.js")
    let client = await JSON.parse(sessionStorage.getItem('client'));
    let pushAuth = JSON.parse(sessionStorage.getItem('pushAuth'));
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready
            .then(function (reg) {
                reg.pushManager
                    .getSubscription()
                    .then(function (existedSubscription) {
                        console.log(2);
                        console.log(existedSubscription);
                        if (existedSubscription === null) {
                            reg.pushManager
                                .subscribe({
                                    applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
                                    userVisibleOnly: true,
                                })
                                .then(function (newSubscription) {
                                    console.log(3);
                                    sendSubscription({subscription:newSubscription,client:client && client._id ? client._id : null});
                                })
                                .catch(async () => {
                                    console.log(4);
                                    await navigator.serviceWorker.register("/sw.js")
                                })
                        }
                    });
            });
    }
}

async function sendSubscription(subscription) {
    let base_url = window.base_URL;
    await axios.post(base_url + '/push/subscribe', subscription)
        .then(resp => {
            sessionStorage.setItem('pushAuth', JSON.stringify(resp.data.result))
        }).catch(err => {
            console.error(err);
        })
}