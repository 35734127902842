import React, {Component} from "react";
import BannderHead from "../../components/layout/BannderHead";
import axios from "axios";
import Pagination from "../../components/Pagination";
import DatePicker from "react-datepicker2";
import {groupBy, entries} from "lodash";

var moment = require('moment-jalaali')


export default class AdminOrdersListPage extends Component {
    state = {
        orders: [],
        fullOrders: [],
        pagination: {},
        categories: [],
        translate: {
            fa: {
                send: "ارسال سفارش",
                fast: "ارسال فوری",
                inPerson: "تحویل حضوری",
                normal: "ارسال عادی"
            }

        }
    }

    constructor() {
        super();
        this.state.isShow = 'daily';
    }

    async componentDidMount() {
        await this.getCategories();
        await this.getOrders();

    }

    searchWithDate = async event => {
        event.preventDefault();
        const startDate = moment(this.state.startDate).format('jYYYY/jMM/jDD')
        const endDate = moment(this.state.endDate).format('jYYYY/jMM/jDD')
        let pagination = {};

        let data = null
        await axios.put(window.admin_base_URL + '/order/filterWithDate', {startDate, endDate})
            .then(res => {
                data = res.data.orders
                pagination = res.data.pagination

                data.forEach(order => {
                    let total = 0;
                    order.products.forEach(item => {
                        total += parseInt(item.price)
                    })
                    order.totalPrice = total;


                    order.products = order.products ? groupBy(order.products, ({_id}) => _id) : []
                })
            })
            .catch(err => {
                console.error(err)
            })
        this.setState(() => {
            return {orders: data, pagination: pagination};
        });
    }
    getOrders = async () => {
        let URL = '';
        if (this.props.today) {
            URL = window.admin_base_URL + '/orders/ordersByClient/today/'
        } else {
            URL = window.admin_base_URL + '/orders/ordersByClient'
        }

        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page');
        if (page) URL = window.admin_base_URL + '/orders/ordersByClient?page=' + page
        let pagination = {};
        let data = [];
        await axios.get(URL)
            .then(res => {
                data = res.data.result
                pagination = res.data.pagination

                data.forEach(order => {
                    let total = 0;
                    order.products.forEach(item => {
                        total += parseInt(item.price)
                    })
                    order.totalPrice = total
                    order.products = order.products ? groupBy(order.products, ({_id}) => _id) : []
                })
            }).catch(err => {
                console.error(err)
            })
        this.setState(() => {
            return {orders: data, fullOrders: data, pagination: pagination};
        });
    }
    getCategories = async () => {
        let data = [];
        await axios.get(window.admin_base_URL + '/category/list').then(res => {
            data = res.data.result.reverse();
        }).catch(err => {
            console.error(err)
        })
        this.setState(() => {
            return {categories: data};
        });
    }
    ChangeCategory = async (event) => {
        event.preventDefault();
        let cat_id = event.target.id;
        let filteredArray = [];
        this.state.fullOrders.forEach(item => {
            let order_products = entries(item.products).flatMap(item => item[1]);
            let conditionArray = order_products.filter(item => item.category['_id'] === cat_id)
            if (conditionArray.length > 0) {
                filteredArray.push(item);
            }
        });
        this.setState(() => {
            return {orders: filteredArray};
        });
    }
    handleTarget = async event => {
        let target = event.target;
        this.setState(() => {
            return {isShow: target.value};
        });
    }
    changeStatus = async event => {
        if (window.confirm("تغییر وضعیت به ارسال شده ؟")) {
            const URL = window.admin_base_URL + "/order/sent/" + event.target.id
            await axios.put(URL, {}).then(async () => {
                await this.getOrders();
            }).catch(err => {
                console.error(err)
            })
        }
    }
    deleteOrder = async event => {
        if (window.confirm("آیا از حذف این سفارش اطمینان دارید ؟")) {
            const URL = window.admin_base_URL + "/order/delete/" + event.target.id;
            const smsURL = window.admin_base_URL + '/order/delete/sms/' + event.target.id  ;
            await axios.put(URL, {}).then(async () => {
                await axios.put(smsURL, {}).then(async ()=>{
                    await this.getOrders();
                });
            }).catch(err => {
                console.error(err)
            })
        }
    }
    showAll = async (event) => {
        event.preventDefault();
        this.setState(() => {
            return {orders: this.state.fullOrders};
        });
    }

    render() {


        let {orders, pagination, categories} = this.state;
        let {today} = this.props;

        return (
            <div className="ps-page pb-75">
                <BannderHead className="no-print" title={today ? 'سفارشات ارسال امروز' : 'کل سوابق سفارشات'}/>
                <BannderHead className="just-print"
                             title={today ? 'سفارشات ارسال ' + moment(new Date()).format('jYYYY/jMM/jDD') : 'کل سوابق سفارشات'}/>
                <div className="ps-section " dir='ltr'>

                    <div className="ps-section__left">
                        <div className="ps-section__content">
                            <div className="ps-block--product-menu ps-tab-root">
                                <div className="ps-block__header no-print">
                                    <ul className="ps-tab-list" dir="rtl">
                                        <li>
                                            <a href="/" onClick={this.showAll} style={{'cursor': 'pointer'}}>
                                                همه
                                            </a>
                                        </li>
                                        {categories.map((category, index) => {
                                            return <li key={index}><a href="/" onClick={this.ChangeCategory}
                                                                      style={{'cursor': 'pointer'}}
                                                                      id={category._id}>{category.title}</a></li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='ml-4 mb-4 d-flex'>
                    <div className="w-50 text-right">
                        <span>تعداد کل: {pagination.count}</span>&nbsp;;&nbsp;
                        <span>تعداد نمایش در صفحه : {pagination.limit}</span>&nbsp;;&nbsp;
                        <span>شماره صفحه : {pagination.page}</span>
                    </div>
                    <div className="w-50">
                        <Pagination pagination={pagination}/>
                    </div>
                </div>
                {!today ? <div className="mb-30 d-flex align-items-center dateFilterRage">
                    <DatePicker
                        placeholder='فیلتر از تاریخ'
                        timePicker={false}
                        value={this.state.startDate}
                        isGregorian={false}
                        className='form-control bg-white mw-150px'
                        onChange={startDate => this.setState({startDate})}
                    />
                    <DatePicker
                        placeholder='فیلتر تا تاریخ'
                        timePicker={false}
                        value={this.state.endDate}
                        isGregorian={false}
                        className='form-control bg-white mw-150px'
                        onChange={endDate => this.setState({endDate})}
                    />
                    <a href="/" className="btn h-100 " onClick={this.searchWithDate}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                             className="bi bi-search" viewBox="0 0 16 16">
                            <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                        </svg>
                    </a>
                </div> : ''}
                <table className="table table-hover table-striped text-right mb-4 font-big-print">
                    <thead>
                    <tr>
                        <th scope="col" className="no-print">تاریخ سفارش</th>
                        <th scope="col">مشخصات مشتری</th>
                        <th scope="col">جزئیات سفارش</th>
                        <th scope="col" className="no-print">توضیحات</th>
                        <th scope="col" className="no-print">مبلغ سفارش</th>
                        <th scope="col" className="no-print">نوع ارسال</th>
                        <th scope="col" className="no-print">وضعیت</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.map((item, index) => {
                        return <tr key={index}>
                            <td className="no-print">{item.created}</td>
                            {item.client ? <td className="w-print-30">
                                <div className="no-print bolder">{item.client.shopTitle}</div>
                                <div className="just-print bolder">نام فروشگاه: {item.client.shopTitle}</div>
                                <div className="no-print">{item.client.name}</div>
                                <div className="just-print">نام مشتری: {item.client.name}</div>
                                <div className="no-print">{item.client.phone}</div>
                                <div className="just-print">تلفن مشتری: {item.client.phone}</div>
                                <div className="no-print">{item.client.address}</div>
                                <div className="just-print">آدرس ارسال: {item.client.address}</div>
                                <div className='mb-10 mb-print-0'>کدپستی: {item.client.postCode}</div>
                                <div className="just-print">نوع ارسال: {this.state.translate.fa[item.howToSend]}</div>
                                <div className="no-print">مبلغ سفارش: {item.totalPrice.toLocaleString()}&nbsp;تومان
                                </div>
                                <div className="just-print">تاریخ ثبت سفارش: {item.created}</div>
                                <div className="just-print bolder">توضیحات مشتری: {item.description}</div>
                                <a href={"clients/" + item.client._id + "/orderHistory"}
                                   className='mb-10 d-inline-block no-print ps-btn--outline ps-btn--sm'>سابقه
                                    سفارشات</a>
                            </td> : <td></td>}
                            <td>

                                {Object.entries(item.products)
                                    .sort((a, b) => a[0] > b[0] ? 1 : -1)
                                    .map((item, index) => {
                                        return <div key={index}
                                                    className="col-6-print"> {item[1].length} {item[1][0].title}{item[1][0].weight ?
                                            <span> .... {item[1][0].weight} گرم</span> : ''}</div>
                                    })}

                            </td>
                            <td className="no-print">{item.description}</td>
                            <td className="no-print">{item.totalPrice.toLocaleString()}&nbsp;تومان</td>
                            <td className="no-print">{this.state.translate.fa[item.howToSend]}</td>
                            <td className="no-print">
                                {item.status === 'sent' ? <div className="mb-10">ارسال شده</div> : ""}
                                {item.status === 'registered' ? <div className="mb-10">ثبت شده</div> : ""}
                                {item.status === 'deleted' ? <div className="mb-10">حذف شده</div> : ""}
                                <button type="button"
                                        className="ps-btn mb-10 ps-btn--outline ps-btn--sm mx-1 text-nowrap"
                                        onClick={this.changeStatus}
                                        hidden={item.status === 'sent'}
                                        id={item._id}>تغییر به ارسال شده
                                </button>
                                <button type="button" className="ps-btn ps-btn--outline ps-btn--sm mx-1 text-nowrap"
                                        onClick={this.deleteOrder}
                                        hidden={item.status === 'sent' || item.status === 'deleted'}
                                        id={item._id}>حذف سفارش
                                </button>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
                <Pagination pagination={pagination}/>
            </div>
        );
    }
}
