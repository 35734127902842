import React, {Component} from "react";
import axios from "axios";
import BannderHead from "../../components/layout/BannderHead";

export default class AdminPushFormPage extends Component {

    state = {
        push: {
            title: '',
            message: '',
            type: '',
        },
        status: 0,
        types: [
            {_id: 'SMS', title: 'پیامک'},
            {_id: 'Notification', title: 'اعلان برنامه'},
        ]
    }

    handleChange = event => {
        this.setState({
            push: {
                ...this.state.push,
                [event.target.name]: event.target.value
            }
        });
    };
    createPush = async event => {
        event.preventDefault();

        const {push} = this.state;
        let URL = window.admin_base_URL + "/push/create"

        await axios.post(URL, push)
            .then(async () => {

                await this.setState({
                    push, status: 200
                });
                window.location.href = "/adminPanel/push"
            })
            .catch(async err => {
                await this.setState({
                    push, status: 400
                });
                console.error(err)
            })


    };

    render() {

        const {
            title,
            type,
            message,
        } = this.state.push;
        const {status, types} = this.state;
        return (
            <div id='AdminProductForm'>
                <BannderHead title={"فرم ایجاد اطلاعیه"}/>
                <form className="ps-form--adminPanel" onSubmit={this.createPush}>
                    <div className="row" dir='rtl'>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm "
                                    placeholder='عنوان'
                                    type="text"
                                    name='title'
                                    required
                                    value={title || ""}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                            <div className="form-group">
                                <select className=" form-control shadow-sm" value={type}
                                        name='type' onChange={this.handleChange}>
                                    <option value='' disabled>انتخاب نوع ارسال</option>
                                    {types.map(item => {
                                        return <option value={item._id}
                                                       key={item._id}>{item.title}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm"
                                    type="text"
                                    placeholder='متن اطلاعیه'
                                    name='message'
                                    value={message}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>

                    </div>
                    {status === 200 ?
                        <div className="alert alert-success text-right" role="alert">
                            ارسال با موفقیت انجام شد
                        </div> : ''
                    }
                    {status === 400 ?
                        <div className="alert alert-danger text-right" role="alert">
                            خطا در ثبت اطلاعات رخ داده است.
                        </div> : ''
                    }

                    <button className="ps-btn  ps-btn--sm mr-auto ml-40" type="submit">ارسال
                    </button>
                </form>
            </div>
        );
    }
}
